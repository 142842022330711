<template>
  <v-container fluid pa-0>
    <v-container px-0 pt-10>
      <v-row>
        <v-col>
          <v-list nav>
            <v-list-item-group v-model="group">
              <v-list-item incative class="py-3" style="display: block;">
                <router-link
                  :to="storeHomeLink"
                  style="text-decoration: none"
                  class="black--text"
                >
                  <v-row justify="center" align="center">
                    <v-col cols="3">
                      <v-avatar size="60px">
                        <v-img :src="logo"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col class="text-h6">
                      {{ name }}
                    </v-col>
                  </v-row>
                </router-link>
              </v-list-item>
              <v-divider class="py-2"></v-divider>
              <template v-if="pages">
                <v-list-item
                  class="px-15"
                  v-for="(k, i) in pages"
                  :key="`page-${i}`"
                  @click="goToPage(k.title)"
                >
                  <v-list-item-title class="text-subtitle-1">
                    <span class="custom-font abel">
                      {{ k.title }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="pages" class="py-2"></v-divider>
              </template>
              <router-link
                :to="showAllLink"
                style="text-decoration: none"
                class="black--text"
              >
                <v-list-item class="px-15">
                  <v-list-item-title class="text-subtitle-1">
                    <span class="custom-font abel"> All Products </span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link
                v-for="(k, i) in categories"
                :to="categoryHomeLink(k)"
                style="text-decoration: none"
                class="black--text"
                :key="i"
              >
                <v-list-item class="px-15">
                  <v-list-item-title class="text-subtitle-1">
                    <span class="custom-font abel">
                      {{ getCategoryName(k) }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid px-0 pt-0 pb-15>
      <v-card flat :color="colorLightened" class="py-5">
        <v-card-text>
          <v-row
            align="center"
            justify="center"
            class="text-center text-subtitle-1"
          >
            <v-col class="text-subtitle-2 black--text" py-5> Contact Us </v-col>
            <Contacts :socials="socials" />
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-bottom-navigation fixed light height="40">
      <v-row class="highlight-gradient">
        <v-btn block @click="goHome()">
          <v-col class="pt-0">
            <v-row
              justify="center"
              align="center"
              class="px-0 mx-0"
              style="width: 100%"
            >
              <v-col
                class="white--text text-title text-center"
                style="width: 100%"
              >
                Made with Chopin by Apio
              </v-col>
            </v-row>
          </v-col>
        </v-btn>
      </v-row>
    </v-bottom-navigation>
  </v-container>
</template>

<style lang="scss">
.highlight-gradient {
  background: linear-gradient(90deg, #fc5c7d, #6a82fb);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import tinycolor from "tinycolor2";
import Contacts from "@/components/hero/Contacts";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

Object.defineProperty(Vue.prototype, "$tinycolor", { value: tinycolor });

export default {
  name: "CategoryNav",
  props: ["categories", "color", "socials", "name", "pages", "logo"],
  components: { Contacts },
  mixins: [ChopinProductMixin],
  data() {
    return {
      group: null,
    };
  },
  computed: {
    colorLightened() {
      return this.$tinycolor(this.color).lighten(35).toHexString();
    },
  },
  methods: {
    ...mapActions("chopin", ["resetFetchStoreAction"]),
    goToPage(title) {
      this.$router.push(this.getPageLink(title));
    },
    goHome() {
      this.resetFetchStoreAction();
      this.$router.push({ name: "intro" });
    },
  },
  created() {
    window._ = require("tinycolor2");
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
